<template>
  <div class="homework">
    <div v-for="question in questions" :key="question.questions_id" class="question-block">
      <h2 class="title" style="white-space: pre-wrap;">{{ question.title }}</h2>
      <el-radio-group v-model="question.selected" @change="submit(question, $event)">
        <el-radio :disabled="question.disabled" v-for="option in question.optionArr" :key="option.label" :label="option.label">{{ option.text }}</el-radio>
      </el-radio-group>
      <div v-if="question.selected" class="analysis">
        <h3 class="analysis-title">解析：{{ question.is_correct }}</h3>
        <p>{{ question.explanation }}</p>
      </div>
    </div>
    <el-button class="next-button" @click="getQuestionList(1)">推荐下一题</el-button>
  </div>
</template>

<script>
import { getQuestion, editanswer } from "../../../request/api";

export default {
  data () {
    return {
      questions: [],
      isLoading: false
    }
  },
  methods: {
    async submit (question, eventValue) {
      let postData = {
        questions_id: question.questions_id,
        answers_id: question.answers_id,
        eventValue: eventValue
      }
      const res = await editanswer(postData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      question.is_correct = res.data.data
      question.disabled = true
    },
    // 获取推荐问题
    async getQuestionList(questionsNumber) {
      // some方法检查，至少有一个null
      if (questionsNumber === 1) {
        if (this.questions.some(question => question.selected === null)) {
          this.$message.error('请完成答题！')
          return
        }
      }
      if (this.isLoading) return

      try {
        this.isLoading = true
        const res = await getQuestion(questionsNumber)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }

        this.questions = res.data.data.map(question => {
          return {
            questions_id: question.questions_id,
            title: question.title,
            // { label, text } 由于属性名和变量名相同，所以使用了对象字面量的简写语法。
            optionArr: Object.entries(question.optionArr).map(([label, text]) => ({ label, text })),
            explanation: question.explanation,
            selected: null,
            disabled: false,
            tags_id: question.tags_id,
            answers_id: question.answers_id
          }
        })
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    }
  },
  mounted() {
    this.getQuestionList(3)
  }
}
</script>

<style lang="less" scoped>
.homework {
  width: 80%;
  margin: 40px auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  .question-block {
    margin-bottom: 40px;
    .title {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
      border-bottom: 2px solid #eee;
      padding-bottom: 10px;
    }
    .el-radio-group {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      .el-radio {
        margin-bottom: 10px;
        font-size: 18px;
        color: #555;
      }
    }
    .analysis {
      margin-top: 20px;
      background-color: #eef1f5;
      padding: 10px;
      border-radius: 5px;
      .analysis-title {
        font-size: 20px;
        color: #444;
        margin-bottom: 10px;
      }
    }
  }
  .next-button {
    display: block;
    margin: 0 auto;
    background-color: #409eff;
    color: #ffffff;
    border: none;
    border-radius: 25px;
    padding: 12px 30px;
    font-size: 18px;
    cursor: pointer;  // 鼠标悬停形状
    transition: background-color 0.3s ease;  // 平滑过渡效果
    &:hover {
      background-color: #66b1ff;
    }
  }
}
</style>